@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

input, textarea, button {
    outline: none;
}

textarea {
    resize: none;
}

a[href^=tel] {
    text-decoration:inherit;
    color: inherit;
 }

.row {
    margin: 0 auto;
    &--large {
        width: 1400px;
    }
    &--medium {
        width: 1200px;
    }
    &--small {
        width: 960px;
    }
    &--full {
        width: 100%;
    }
    &--mini {
        width: 600px;
    }
    &--large-right {
        width: 1660px;
        margin-left: auto;
        margin-right: 0;
    }
}

.button {
    display: block;
    background-color: #00925B;
    color: #fff;
    border-radius: 4px;
    width: 248px;
    padding: 14px 6px;
    text-align: center;
    transition: .15s all ease-in-out;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: #007B4C;
        text-decoration: underline;
    }
    img {
        margin-left: 7.5px;
        width: 15px;
    }
}

.section-title {
    position: relative;
    font-size: 2.25em;
    color: #333;
    text-align: center;
    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 78px;
        height: 4px;
        background-color: #00925B;
    }
}

.section-title--colored {
    color: #fff;
    &::after {
        background-color: #EBBF2C;
    }
}

// bg
.grey-bg {
    background-color: #F4F5F6;
}

// MARGIN MODIFIERS
.margin-top-sm {
    margin-top: 20px;
}
.margin-top-md {
    margin-top: 50px;
}
.margin-top-lg {
    margin-top: 60px;
}
.margin-top-xl {
    margin-top: 200px;
}
.margin-bottom-sm {
    margin-bottom: 20px;
}
.margin-bottom-md {
    margin-bottom: 50px;
}
.margin-bottom-lg {
    margin-bottom: 60px;
}
.margin-bottom-xl {
    margin-bottom: 120px;
}


// PADDING MODIFIERS
.padding-sm {
    padding: 20px
}
.padding-md {
    padding: 50px
}
.padding-v-xl {
    padding: 240px 0;
}
.padding-v-lg {
    padding: 100px 0;
}
.padding-v-md {
    padding: 50px 0;
}
.padding-v-sm {
    padding: 20px 0;
}
.padding-v-sm-top {
    padding-top: 20px;
}
.padding-v-md-top {
    padding-top: 50px;
}
.padding-v-lg-top {
    padding-top: 100px;
}
.padding-v-xl-top {
    padding-top: 240px;
}
.padding-v-sm-bottom {
    padding-bottom: 20px;
}
.padding-v-md-bottom {
    padding-bottom: 50px;
}
.padding-v-lg-bottom {
    padding-bottom: 100px;
}
.padding-v-xl-bottom {
    padding-bottom: 240px;
}
.padding-h-right-section {
    padding-left: 260px;
}
.padding-h-right-sm {
    padding-right: 40px;
}
.padding-h-right-md {
    padding-right: 80px;
}
.padding-h-right-lg {
    padding-right: 220px;
}

// POP-UP
.offer__price__prefix {
    color: #666;
    font-weight: 500;
    font-size: 0.825em;
}

.pop-up {
    display: none;
    position: fixed;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
}

.pop-up__inner {
    position: fixed;
    z-index: 999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.225);
    border-radius: 6px;
}

.pop-up__head {
    margin-bottom: 2.5em;
}

.pop-up__title {
    font-size: 2.5rem;
    color: #333333;
    font-weight: 500;
}

.pop-up__subtitle {
    color: #666;
    font-weight: 400;
}

.pop-up__info {
    color: #333;
    margin-bottom: 0.5em;
}

.pop-up__picture {
    max-width: 420px;
}

.pop-up__container {
    display: flex;
    justify-content: space-between;
}

.pop-up__container .offer__price {
    text-align: left;
}

.pop-up__close {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
    width: 2.5em;
}

.pop-up__overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
}


// NAVIGATION
.navigation {
    z-index: 9999;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #E5E5E5;
    background-color: #fff;
    width: 100vw;
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__menu, &__contact {
        display: flex;
        align-items: center;
    }
    &__contact {
        &__item {
            margin-right: 25px;
            display: flex;
            p {
                margin-left: 10px;
            }
        }
        img {
            width: 146px;
        }
    }
    &__home {
        z-index: 999;
        display: flex;
        align-items: center;
    }
    &__logo {
        width: 146px;
        //margin-right: 35px;
    }
    &__item {
        display: inline-block;
        margin-right: 20px;
        font-size: 1em;
        transition: .33s all ease-in-out;
    }
    &__link {
        color: #333;
        position: relative;
        transition: .33s all ease-in-out;
        &::after {
            content: '';
            transition: .33s all ease-in-out;
            opacity: 0;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #00925B;
        }
    }
    &__item:hover,
    &__item--active {
        .navigation__link {
            color: #00925B;
            &::after {
                opacity: 1;
            }
        }
    }
    &__item--active {
        font-weight: 600;
    }
    &__burger {
        display: none;
        position: absolute;
        width: 25px;
        height: 15px;
        left: 0;
        top: 30px;
        &::before, &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 25px;
            height: 3px;
            background-color: #007B4C;
            transition: .15s all ease-in-out;
        }
        &::after {
            bottom: 0;
        }
        .middle-line {
            opacity: 1;
            width: 25px;
            height: 3px;
            background-color: #007B4C;
            transition: .15s all ease-in-out;
            margin-top: 6px;
        }
    }
    &__burger--active {
        &::after {
            bottom: 0;
            transform: rotate(45deg);
        }
        &::before {
            bottom: 0;
            transform: rotate(-45deg);
        }
        .middle-line {
            opacity: 0;
        }
    }
}

// HEADER
.header {
    height: 75vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__intro {
        width: 32%;
    }
    &__title {
        font-size: 2.5rem;
        line-height: 1.33;
        color: #333;
    }
    &__title--colored {
        color: #00925B;
    }
    &__paragraph {
        color: #333;
    }
    &__circle {
        z-index: 1;
        position: absolute;
        top: 34%;
        left: 20%;
        transform: translateY(-50%);
        width: 140px;
        height: 140px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        background-color: #007B4C;
        border: 4px solid #38A179;
        &::before {
            content: '';
            position: absolute;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            border: 2px solid #007B4C;
            -webkit-animation: ping 1.6s ease-in-out infinite both;
	        animation: ping 1.6s ease-in-out infinite both;
        }
    }
    &__picture {
        position: relative;
        width: 65%;
        height: 100%;
        //background-image: url(../assets/new-header.jpg);
        //background-size: cover;
        //background-position: center;
        //clip-path: polygon(22.5% 0, 100% 0, 100% 100%, 0% 100%);
        //overflow: hidden;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
            width: 85%;
        }
        &::after {
            display: none;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270.34deg, rgba(0, 146, 91, 0.77) 0.31%, rgba(0, 146, 91, 0.8) 93.57%);
        }
    }
    &__scroll-down {
        cursor: pointer;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        color: #333;
        text-transform: uppercase;
        font-size: 0.75em;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
        .scroll-down-icon {
            width: 34px;
            display: block;
            margin-top: 6px;
        }
    }
}

// OFFER SECTION
.offer {
    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 12px 24px 12px;
        border: 2px solid #E1E1E1;
        width: calc(100%/3 - 20px);
        margin: 10px;
        transition: .25s all ease-in-out;
        &:hover {
            border: 2px solid #00925B;
            .offer__image {
                transform: scale(1.15);
            }
        }
    }
    &__title {
        font-size: 1.5em;
        color: #333;
    }
    &__discount-box {
        position: absolute;
        left: 50%;
        top: -16px;
        transform: translateX(-50%);
        width: 172px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00925B;
        text-transform: uppercase;
        color: #fff;
        font-size: 0.875em;
        clip-path: polygon(50% 0%, 100% 0, 92.5% 50%, 100% 100%, 0 100%, 7.5% 50%, 0 0);
    }
    &__image {
        transition: .25s all ease-in-out;
        width: 55%;
    }
    &__price {
        text-align: center;
        &__cost {
            font-size: 2em;
            font-weight: bold;
            color: #00925B;
        }
        &__contact {
            font-size: 1.35rem;
        }
        &__discount {
            font-size: 1.25em;
            color: #AEAEAE;
            text-decoration: line-through;
        }
        &__info {
            font-size: 1em;
            color: #666;
            display: block;
            margin-top: -10px;
        }
    }
    &__info-label {
        font-size: 0.875em;
        color: #666;
        margin-top: 5px;
    }
}


// ADVANTAGES SECTION
.advantages {
    background-color: #00925B;
    .section-title {
        color: #fff;
    }
    &__inner {
        display: flex;
        justify-content: center;
    }
    &__item {
        position: relative;
        width: calc(100%/4);
        text-align: center;
        padding: 0px 15px;
        &__inner {
            display: flex;
            align-items: flex-end;
            text-align: left;
            padding: 50px 15px;
            transition: .33s all ease-in-out;
            .advantages__icon .a {
                transition: .33s all ease-in-out;
            }
        }
        &:nth-last-child(1) {
            &::after {
                display: none;
            }
        }
    }
    &__description {
        font-size: 1rem;
        color: #fff;
        margin-left: 10px;
        transform: translateY(5px);
        transition: .33s all ease-in-out;
    }
    &__icon {
        width: 45px;
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__copyright {
        p {
            color: #AEAEAE;
            font-size: 0.75rem;
        }
    }
    &__menu {
        &__item {
            position: relative;
            display: inline-block;
            font-size: 0.75rem;
            margin-left: 10px;
            margin-right: 10px;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -15px;
                width: 4px;
                height: 4px;
                background-color: #E5E5E5;
            }
            &:nth-child(1) {
                &::after {
                    display: none;
                }
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
            a {
                color: #666666;
            }
        }
    }
}


// LAPTOP BREAKPOINT
@media (max-width: 1560px) {
    body {
        font-size: 14px;
    }
    .padding-v-lg {
        padding: 70px 0;
    }
    .row {
        &--large {
            width: 1300px;
        }
        &--small {
            width: 1100px;
        }
    }
    .padding-h-right-section {
        padding-left: 80px;
    }
    .padding-h-right-sm {
        padding-right: 20px;
    }
    .padding-h-right-md {
        padding-right: 40px;
    }
    .padding-h-right-lg {
        padding-right: 80px;
    }
    .info-bar__text {
        width: 59%;
    }
    .header__circle {
        width: 120px;
        height: 120px;
    }
    .header {
        height: 600px;
        &__intro {
            width: 34%;
        }
        &__picture {
            width: 66%;
        }
    }
    .car-advantage__list-item {
        margin: 17.5px 0;
    }
}

// SMALLER DEVICE BREAKPOINT
@media (max-width: 1320px) {
    .padding-v-lg {
        padding: 60px 0;
    }
    .row {
        &--large {
            width: 95%;
        }
        &--small {
            width: 87.5%;
        }
    }
    .car-advantage {
        width: 800px !important;
        &__title, &__description {
            width: 160px;
        }
    }
    .header__picture img {
        width: 97.5%;
    }
}

@media (max-width: 1000px) {
    .header {
        &__intro {
            width: 45%;
        }
        &__picture {
            width: 55%;
        }
    }
}

// TABLET BREAKPOINT
@media (max-width: 900px) {
    .car-advantage {
        width: 95vw !important;
        &__inner {
            display: none;
        }
    }
    .car-advantage-phone__inner {
        display: block;
    }
    .car-advantage-phone__wrapper {
        height: 500px;
    }
    .navigation {
        // display: none;
        &__inner {
            flex-direction: column;
            align-items: flex-end;
        }
        &__home {
            display: block;
        }
        &__logo {
            margin-right: 0px;
        }
        &__items {
            pointer-events: none;
            height: 0;
            margin-top: -10px;
            opacity: 0;
            align-self: flex-start;
        }
        &__items--active {
            pointer-events: all;
            height: auto;
            margin-top: 20px;
            opacity: 1;
            transition: .3s all ease-in-out;
        }
        &__items--unactive {
            height: 0;
            margin-top: -10px;
            opacity: 0;
            transition: .3s margin ease-in-out;
        }
        &__item {
            display: block;
            margin: 7.5px 0;
            font-size: 1.2em;
        }
        &__contact {
            margin-top: 30px;
            display: none;
            align-self: flex-start;
            &__item {
                margin-right: 15px;
                p {
                    font-size: 0.8em;
                }
                img {
                    width: 16px;
                }
            }
        }
        &__menu {
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
        }
        &__burger {
            display: block;
        }
    }
    .padding-v-lg {
        padding: 60px 0;
    }
    .padding-v-lg-top {
        padding-top: 60px;
    }
    .padding-h-right-section {
        padding-left: 40px;
    }
    .button {
        width: 200px;
    }
    .row {
        &--large {
            width: 95%;
        }
        &--small {
            width: 87.5%;
        }
    }
    .offer {
        &__price__cost {
            font-size: 1.65em;
        }
        &__item {
            width: calc(100% / 2 - 20px);
        }
    }
    .advantages {
        &__item {
            padding: 0 20px;
            width: calc(100%/2);
            &__inner {
                justify-content: center;
                padding: 30px 20px;
            }
        }
        &__inner {
            padding: 50px 20px;
            flex-wrap: wrap;
        }
        &__description {
            font-size: 1.25rem;
        }
    }
}

// MOBILE DEVICE BREAKPOINT
@media (max-width: 640px) {
    .pop-up__container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .pop-up__picture {
        max-width: 100%;
    }

    .pop-up__inner {
        width: 85%;
        padding: 60px 20px 20px;
    }

    .pop-up__col {
        width: 100%;
        text-align: center;
    }

    .pop-up .offer__price {
        text-align: center;
    }

    .pop-up .button {
        margin-left: auto;
        margin-right: auto;
    }

    .advantages__item__inner img {
        width: 52px;
    }
    .padding-h-right-section {
        padding-left: 0;
    }
    .padding-v-lg {
        padding: 60px 0;
    }
    .padding-v-lg-top {
        padding-top: 60px;
    }
    .padding-v-lg-bottom {
        padding-bottom: 60px;
    }
    .padding-v-md-top {
        padding-top: 30px;
    }
    .padding-v-md-bottom {
        padding-bottom: 30px;
    }
    .margin-top-md {
        margin-top: 20px;
    }
    .row {
        &--large {
            width: 93.5vw;
        }
        &--small {
            width: 90vw;
        }
        &--mini {
            width: 85vw;
        }
        &--padding-v-big {
            padding: 60px 0;
        }
        &--padding-v-small {
            padding: 20px 0;
        }
    }
    .header {
        height: auto;
        flex-direction: column;
        &__circle {
            width: 100px;
            height: 100px;
            font-size: 0.8em;
            left: 7%;
            top: 38%;
            &::before {
                width: 100px;
                height: 100px;
            }
        }
        &__intro {
            width: 100%;
            padding: 45px 20px;
        }
        &__picture {
            width: 100%;
            height: 160px;
            clip-path: unset;
        }
        &__scroll-down {
            bottom: 3%;
        }
    }
    .offer {
        &__inner {
            flex-direction: column;
        }
        &__item {
            width: 87.5%;
            margin: 15px auto;
        }
    }
    .advantages {
        &__inner {
            flex-wrap: wrap;
            padding: 30px 0;
        }
        &__description {
            font-size: 1em;
        }
        &__icon {
            width: 37.5px;
        }
        &__item {
            width: calc(100%/2);
            padding: 0 10px;
            margin: 0;
            &::after {
                height: 65%;
            }
            &:nth-child(2) {
                &::after {
                    display: none;
                }
            }
            &__inner {
                padding: 20px 5px;
            }
        }
    }

    .footer {
        flex-direction: column;
        margin-top: 25px;
        &__menu  {
            margin-top: 20px;
        }
    }
}




.labels {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.label-item {
    background-color: #00925B;
    color: white;
    padding: 5px 15px;
}

@media (max-width: 768px) {
    .labels {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .label-item {
        margin-right: 5px;
        margin-bottom: 5px;
    }
}


.offer__price__details {
    span {
        color: #666;
    }
}
